import {
  useQuery,
  QueryClient,
  queryOptions,
  useMutation,
} from '@tanstack/vue-query';
import { notificationsApi } from '~/utils/api';
import {
  SiteNotificationUpdateRequest,
  SiteNotificationSnoozeRequest,
} from '~/api/data-contracts';

export const notificationsQueryKey = () => ['notifications'];
export const invalidateNotifications = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: notificationsQueryKey() });

const notificationsQueryConfig = () =>
  queryOptions({
    queryKey: notificationsQueryKey(),
    queryFn: () => notificationsApi.notificationsList(),
  });

export const useNotificationsQuery = () => {
  const config = computed(() => notificationsQueryConfig());

  return useQuery(config);
};

export const useUpdateNotificationMutation = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: ({
      notificationId,
      data,
    }: {
      notificationId: string;
      data: SiteNotificationUpdateRequest;
    }) => notificationsApi.notificationsUpdate(notificationId, data),
    onSuccess: () => {
      invalidateNotifications(queryClient);
      invalidateNotificationsCount(queryClient);
    },
  });

export const useCreateSnoozeNotificationMutation = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: ({
      notificationId,
      data,
    }: {
      notificationId: string;
      data: SiteNotificationSnoozeRequest;
    }) => notificationsApi.notificationsSnoozeCreate(notificationId, data),
    onSuccess: () => {
      invalidateNotifications(queryClient);
      invalidateNotificationsCount(queryClient);
    },
  });

export const notificationsCountQueryKey = () => ['notifications-count'];
export const invalidateNotificationsCount = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: notificationsCountQueryKey() });

const notificationsCountQueryConfig = () =>
  queryOptions({
    queryKey: notificationsCountQueryKey(),
    queryFn: () => notificationsApi.notificationsCountRetrieve(),
  });

export const useNotificationsCountQuery = () => {
  const config = computed(() => notificationsCountQueryConfig());

  return useQuery(config);
};
