<script lang="ts" setup>
  import { ref, computed } from 'vue';
  import OverlayPanel from 'primevue/overlaypanel';
  import {
    SiteNotification,
    SiteNotificationReactionEnum,
  } from '~/api/data-contracts';
  import VNotificationsWidgetListItem from './VNotificationsWidgetListItem.vue';

  const root = ref();
  defineExpose({
    root,
  });

  const props = defineProps<{
    notifications: SiteNotification[];
  }>();

  const isShowViewed = ref(false);

  const isAllNotificationsViewed = computed(() => {
    return props.notifications.every(
      (notification) =>
        notification.reaction === SiteNotificationReactionEnum.Viewed,
    );
  });

  const isAllNotificationsNew = computed(() => {
    return props.notifications.every(
      (notification) =>
        notification.reaction === SiteNotificationReactionEnum.New,
    );
  });

  const toggleLabel = computed(() => {
    return isShowViewed.value ? 'Hide read notifications' : 'All notifications';
  });

  const isNotificationViewed = (notification: SiteNotification) => {
    return notification.reaction === SiteNotificationReactionEnum.Viewed;
  };

  const filteredNotifications = computed<SiteNotification[]>(() => {
    return isShowViewed.value || isAllNotificationsViewed.value
      ? props.notifications.toSorted((a, b) => {
          return (
            Number(b.reaction === SiteNotificationReactionEnum.New) -
            Number(a.reaction === SiteNotificationReactionEnum.New)
          );
        })
      : props.notifications.filter(
          (notification) =>
            notification.reaction === SiteNotificationReactionEnum.New,
        );
  });

  const hide = () => {
    root.value.hide();
  };
</script>

<template>
  <OverlayPanel
    ref="root"
    class="m-4 max-h-[80vh] w-[320px] overflow-auto rounded-12 bg-grayscale-100 shadow dark:bg-grayscale-700 tablet:w-[380px]"
  >
    <template v-if="notifications.length > 0">
      <div
        v-if="!isAllNotificationsViewed && !isAllNotificationsNew"
        class="border-b border-grayscale-300 py-12 pl-16 pr-10 text-grayscale-400 last:border-b-0 dark:border-grayscale-500 dark:text-grayscale-200"
      >
        <button @click="isShowViewed = !isShowViewed">{{ toggleLabel }}</button>
      </div>
      <div
        v-for="notification in filteredNotifications"
        :key="notification.id"
        class="flex-column flex border-b border-grayscale-300 py-12 pl-16 pr-10 last:border-b-0 dark:border-grayscale-500"
        :class="{ 'bg-grayscale-200': isNotificationViewed(notification) }"
        data-testid="list"
      >
        <VNotificationsWidgetListItem
          :notification="notification"
          @click="hide"
        />
      </div>
    </template>
    <div
      v-else
      class="py-12 pl-16 pr-10"
    >
      You've had no notifications
    </div>
  </OverlayPanel>
</template>
