<script lang="ts" setup>
  import { ref, watchEffect } from 'vue';
  import { useQueryClient } from '@tanstack/vue-query';
  import { useCreateSnoozeNotificationMutation } from '~/composables/query/notifications';
  import Dropdown from 'primevue/dropdown';
  import { SiteNotification } from '~/api/data-contracts';
  import { useErrorParser } from '~/composables/useErrorParser';
  import VInputWrapper from '~/components/VInputWrapper/VInputWrapper.vue';
  import dayjs from '~/utils/dayjs';

  const { errors, setRawErrors } = useErrorParser();

  type SnoozeVariant = {
    label: 'in an hour' | 'tomorrow morning' | 'in a week';
    value: () => string;
  };

  const props = defineProps<{
    notificationId: SiteNotification['id'];
  }>();

  const queryClient = useQueryClient();
  const { mutateAsync: createSnoozeNotification, isPending } =
    useCreateSnoozeNotificationMutation(queryClient);

  const selected = ref<SnoozeVariant['value']>();
  const variants = ref<SnoozeVariant[]>([
    {
      label: 'in an hour',
      value: () => dayjs().add(1, 'hour').toISOString(),
    },
    {
      label: 'tomorrow morning',
      value: () =>
        dayjs().add(1, 'day').hour(8).minute(0).second(0).toISOString(),
    },
    {
      label: 'in a week',
      value: () =>
        dayjs().add(1, 'week').hour(8).minute(0).second(0).toISOString(),
    },
  ]);

  watchEffect(async () => {
    if (!selected.value) return;
    await createSnoozeNotification(
      {
        notificationId: props.notificationId,
        data: {
          dateToSchedule: selected.value(),
        },
      },
      {
        onError(e) {
          setRawErrors(e);
          selected.value = undefined;
        },
      },
    );
  });
</script>

<template>
  <VInputWrapper
    :errors="errors"
    name="dateToSchedule"
  >
    <Dropdown
      v-model="selected"
      :options="variants"
      :loading="isPending"
      option-label="label"
      option-value="value"
      :pt="{
        root: 'relative flex items-center',
      }"
      class="w-[160px] select-none text-14 text-grayscale-400"
      placeholder="Set reminder"
    />
  </VInputWrapper>
</template>
