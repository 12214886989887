export function getTextBetweenTags(
  inputString: string,
  tagName: string,
): string | null {
  const regex = new RegExp(`<${tagName}>(.*?)<\\/${tagName}>`);
  const match = inputString.match(regex);
  return match ? match[1] : null;
}

export function replaceTextBetweenTags(
  inputString: string,
  tagName: string,
  newText: string,
): string {
  const regex = new RegExp(`(<${tagName}[^>]*>)(.*?)(<\\/${tagName}>)`, 's');
  return inputString.replace(regex, `$1${newText}$3`);
}
